.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  background-color: #000080;
}

.all-question-container {
  padding: 20px;
}

.avaliate-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  overflow: auto;
  width: 100%;
  max-width: 1400px;
  font-size: 25px;
  margin: auto;
}

.h1-header{
  margin: 0;
  margin-bottom: 12px;
  margin-left: 35px;
}


.styled-button {
  border: 1px solid #707070b8;
  border-radius: 50px;
  opacity: 1;
  padding: 30px;
  margin: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  top: 0;
  left: 0;
  transition: all .15s linear 0s;
  position: relative;
  box-shadow: 8px 8px 0 #707070b8;
  text-decoration: none;
}

.styled-button span {
  font-size: 22px;
  font-weight: bold;
  margin: auto;
}

.styled-button:hover {
  top: 2px;
  left: 2px;
  box-shadow: 6px 6px 0 #70707094;
}

button:active.styled-button {
  top: 8px;
  left: 8px;
  box-shadow: none;
}

.green {
  background: #12F321 0% 0% no-repeat padding-box;
}
.yellow {
  background: #F6EF15 0% 0% no-repeat padding-box;
}
.red {
  background: #F31818 0% 0% no-repeat padding-box;
}

.buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.img-logo {
  height: 80px;
  margin: 20px;
}

.row {
  width: 100%;
  max-width: 100%;
}

/* medias */

@media screen and (width < 750px) {
  .styled-button {
    width: 150px;
    height: 160px;
    font-size: 75px;
  }
  .styled-button span {
    font-size: 16px;
    margin: 10px;
  }
}

@media screen and (width >= 750px){
  .styled-button {
    width: 210px;
    height: 250px;
    font-size: 150px;
  }
}

@media screen and (1450px >= width >=1024px) {
  .styled-button {
    width: 280px;
    height: 296px;
    font-size: 174px;
  }
  .styled-button span {
    font-size: 30px;
  }
}

@media screen and (width>=1450px) {
  .styled-button {
    font-size: 220px;
    width: 350px;
    height: 350px;
  }

  .styled-button span {
    font-size: 38px;
  }
}

.modal {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px #333;
  min-width: 320px;
  position: fixed;
  left: 50%;
  padding: 24px;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 30px;
  opacity: 1;
  font-weight: bold;
  text-align: center;
  color: #707070;
  display: flex;
  align-items: center;
  font-size: 25px;
  max-width: 70vw;
  max-height: 84vh;
  padding: 24px;
}

@media (min-width: 600px) {
  .modal {
    font-size: 45px;
    width: 70%;
    padding: 34px;
  }
}

@media (min-width: 1600px) {
  .modal {
    font-size: 85px;
    width: 1251px;
    height: 576px;
    padding: 34px;
  }
}